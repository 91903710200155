import { MentionType } from "models/documents.model";
import {
  FetchTaxonomyEntitiesAPIRequest,
  UploadTaxonomyFileAPIRequest,
} from "models/taxonomy.models";

import { axiosConfig } from "./axios";

export const fetchTaxonomyEntitiesAPI = async ({
  entityType,
}: FetchTaxonomyEntitiesAPIRequest) => {
  const url = `taxonomy/${entityType}`;
  const response = await axiosConfig("GET", url);
  return response;
};

export const uploadTaxonomyFileAPI = async ({
  file,
  entityType,
}: UploadTaxonomyFileAPIRequest) => {
  const url = `taxonomy/${entityType}`;
  const formData = new FormData();
  formData.append("file", file);
  const response = await axiosConfig("POST", url, formData, true);
  return response;
};

export const deleteTaxonomyAPI = async (entityType: MentionType) => {
  const url = `taxonomy/${entityType}`;
  const response = await axiosConfig("DELETE", url);
  return response;
};

export const downloadTaxonomyTemplateAPI = async () => {
  const url = `taxonomy/template`;
  const response = await axiosConfig("GET", url);
  return response;
};
