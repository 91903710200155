import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchTaxonomyEntitiesAPI,
  uploadTaxonomyFileAPI,
  deleteTaxonomyAPI,
  downloadTaxonomyTemplateAPI,
} from "api/taxonomy";
import { MentionType } from "models/documents.model";
import { InterceptedResponse, Status } from "models/shared";
import {
  FetchTaxonomyEntitiesAPIRequest,
  TaxonomyEntity,
  UploadTaxonomyFileAPIRequest,
} from "models/taxonomy.models";

interface TaxonomyState {
  isFetchingTaxonomyEntries: Status;
  isUploadingTaxonomyFile: Status;
  isDeletingTaxonomy: Status;
  isDownloadingTemplate: Status;
  productTaxonomyEntities: TaxonomyEntity[];
  situationTaxonomyEntities: TaxonomyEntity[];
  selectedTaxonomyTab: MentionType;
}

const initialState = {
  isFetchingTaxonomyEntries: "idle",
  isUploadingTaxonomyFile: "idle",
  isDeletingTaxonomy: "idle",
  isDownloadingTemplate: "idle",
  productTaxonomyEntities: [],
  situationTaxonomyEntities: [],
  selectedTaxonomyTab: "Product",
} as TaxonomyState;

export const fetchTaxonomyEntities = createAsyncThunk(
  "taxonomy/fetchTaxonomyEntities",
  async (request: FetchTaxonomyEntitiesAPIRequest) => {
    const response = await fetchTaxonomyEntitiesAPI(request);
    return response;
  }
);

export const uploadTaxonomyFile = createAsyncThunk(
  "taxonomy/uploadTaxonomyFile",
  async (request: UploadTaxonomyFileAPIRequest) => {
    const response = await uploadTaxonomyFileAPI(request);
    return response;
  }
);

export const deleteTaxonomy = createAsyncThunk(
  "taxonomy/deleteTaxonomy",
  async (entityType: MentionType) => {
    const response = await deleteTaxonomyAPI(entityType);
    return response;
  }
);

export const downloadTaxonomyTemplate = createAsyncThunk(
  "taxonomy/downloadTaxonomyTemplate",
  async () => {
    const response = await downloadTaxonomyTemplateAPI();
    return response;
  }
);

export const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState,
  reducers: {
    setSelectedTaxonomyTab: (state: TaxonomyState, action) => {
      state.selectedTaxonomyTab = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTaxonomyEntities.pending, (state: TaxonomyState) => {
        state.isFetchingTaxonomyEntries = "loading";
      })
      .addCase(
        fetchTaxonomyEntities.fulfilled,
        (state: TaxonomyState, { payload }) => {
          state.isFetchingTaxonomyEntries = "succeeded";
          const entities = (payload as InterceptedResponse)?.data?.entities;
          if (state.selectedTaxonomyTab === "Product") {
            state.productTaxonomyEntities = entities;
          } else {
            state.situationTaxonomyEntities = entities;
          }
        }
      )
      .addCase(fetchTaxonomyEntities.rejected, (state: TaxonomyState) => {
        state.isFetchingTaxonomyEntries = "failed";
      })
      .addCase(uploadTaxonomyFile.pending, (state: TaxonomyState) => {
        state.isUploadingTaxonomyFile = "loading";
      })
      .addCase(uploadTaxonomyFile.fulfilled, (state: TaxonomyState) => {
        state.isUploadingTaxonomyFile = "succeeded";
      })
      .addCase(uploadTaxonomyFile.rejected, (state: TaxonomyState) => {
        state.isUploadingTaxonomyFile = "failed";
      })
      .addCase(deleteTaxonomy.pending, (state: TaxonomyState) => {
        state.isDeletingTaxonomy = "loading";
      })
      .addCase(deleteTaxonomy.fulfilled, (state: TaxonomyState) => {
        state.isDeletingTaxonomy = "succeeded";
        if (state.selectedTaxonomyTab === "Product") {
          state.productTaxonomyEntities = [];
        } else {
          state.situationTaxonomyEntities = [];
        }
      })
      .addCase(deleteTaxonomy.rejected, (state: TaxonomyState) => {
        state.isDeletingTaxonomy = "failed";
      })
      .addCase(downloadTaxonomyTemplate.pending, (state: TaxonomyState) => {
        state.isDownloadingTemplate = "loading";
      })
      .addCase(downloadTaxonomyTemplate.fulfilled, (state: TaxonomyState) => {
        state.isDownloadingTemplate = "succeeded";
      })
      .addCase(downloadTaxonomyTemplate.rejected, (state: TaxonomyState) => {
        state.isDownloadingTemplate = "failed";
      });
  },
});

export default taxonomySlice.reducer;

export const { setSelectedTaxonomyTab } = taxonomySlice.actions;
