import "./BaseLayout.scss";

import { Layout, Menu, MenuProps, Typography, Button } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router";

import { chatbotUrl } from "utils/constants";

type Props = {
  component: ReactNode;
  hideSider?: boolean;
};

export enum NavItems {
  Documents = "/documents",
  Products = "/products",
  Situations = "/situations",
  Benchmarking = "/benchmarking",
  BenchmarkingProductListing = "/benchmarking-list/products",
  BenchmarkingSituationListing = "/benchmarking-list/situations",
  BenchmarkingMetrics = "/benchmarking-metrics",
}

const { Content, Header, Sider } = Layout;
const { Title } = Typography;

const navigationItems: ItemType<MenuItemType>[] = [
  {
    label: "Documents",
    key: NavItems.Documents,
  },
  {
    label: "Products",
    key: NavItems.Products,
  },
  {
    label: "Situations",
    key: NavItems.Situations,
  },
  {
    label: "Benchmarking",
    key: NavItems.Benchmarking,
    type: "group",
    children: [
      {
        label: "Products",
        key: NavItems.BenchmarkingProductListing,
      },
      {
        label: "Situations",
        key: NavItems.BenchmarkingSituationListing,
      },
      {
        label: "Metrics",
        key: NavItems.BenchmarkingMetrics,
      },
    ],
  },
];

const BaseLayout = ({ component, hideSider }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const layoutClassName = classNames({ hidden: hideSider });
  const [selectedKey, setSelectedKey] = useState<string>(
    pathname || NavItems.Documents
  );

  const onItemClick: MenuProps["onClick"] = ({ key }) => {
    setSelectedKey(key);
    navigate(key);
  };

  const onLogoClick = () => {
    setSelectedKey(NavItems.Documents);
    navigate(NavItems.Documents);
  };

  const onChatbotButtonClick = () => {
    window.open(chatbotUrl, "_blank");
  };

  return (
    <Layout className="app-layout">
      <Header className="app-header">
        <Link
          to={NavItems.Documents}
          className="logo-link"
          onClick={onLogoClick}
        >
          <img
            src="/assets/agolo-logo.svg"
            alt="company-logo"
            className="agolo-logo"
          />
        </Link>
        <Title level={3} className="app-title">
          Admin Dashboard
        </Title>
        <Button
          type="default"
          className="chatbot-button"
          onClick={onChatbotButtonClick}
        >
          Open Chatbot
        </Button>
      </Header>
      <Layout className={layoutClassName}>
        {!hideSider && (
          <Sider theme="light" className="app-sider">
            <Menu
              selectedKeys={[selectedKey]}
              items={navigationItems}
              onClick={onItemClick}
            />
          </Sider>
        )}
        <Layout>
          <Content className="app-content-wrapper">{component}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

BaseLayout.defaulyProps = {
  hideSider: false,
};
export default BaseLayout;
