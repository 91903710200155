import { MentionType } from "models/documents.model";
import { FetchTagsRequest, UploadTagsAPIRequest } from "models/tags.model";

import { axiosConfig } from "./axios";

const baseUrl = "tags";

export const uploadTagsAPI = async ({
  entityType,
  file,
}: UploadTagsAPIRequest) => {
  const url = `${baseUrl}/${entityType}`;
  const formData = new FormData();
  formData.append("file", file);
  const response = await axiosConfig("POST", url, formData, true);
  return response;
};

export const fetchTagsAPI = async (request: FetchTagsRequest) => {
  const { entityType, limit, offset } = request;
  const url = `${baseUrl}/${entityType}/documents?limit=${limit}&offset=${offset}`;
  const response = await axiosConfig("GET", url);
  return response;
};

export const fetchTagsReportAPI = async (entityType: MentionType) => {
  const url = `${baseUrl}/${entityType}/report`;
  const response = await axiosConfig("GET", url);
  return response;
};

export const deleteTagsAPI = async (entityType: MentionType) => {
  const url = `${baseUrl}/${entityType}`;
  const response = await axiosConfig("DELETE", url);
  return response;
};

export const downloadTagsTemplateAPI = async () => {
  const url = `golden-tags/template`;
  const response = await axiosConfig("GET", url);
  return response;
};
