import { UploadListProgressProps } from "antd/es/upload/interface";

import {
  DocumentSearchRequest,
  DocumentSearchSort,
} from "models/documents.model";
import { ListingOptions } from "models/shared";

export const baseUrl = process.env.REACT_APP_GRAPH_GEN_URL;
export const chatbotUrl = process.env.REACT_APP_CHATBOT_URL;

export const DEFAULT_ERROR_MSG =
  "Something went wrong, Please try again later.";

export const DEFAULT_TABLE_PAGINATION: ListingOptions = {
  offset: 0,
  limit: 10,
};

export const HIGHLIGHT_CLASS = "highlight-mention";
export const SITUATION_HIGHLIGHT_CLASS = "situation-highlight";
export const SCROLL_THRESHOLD_PERCENTAGE = 95;
export const GHOST_ENTITY_CLASS = "ghost-entity";

export const UPLOAD_PROGRESS_PROPS: UploadListProgressProps = {
  strokeWidth: 10,
  format: () => false,
  status: "active",
  rootClassName: "taxonomy-progress-bar",
};

export const ACCEPT_FILE_TYPE_CSV = "text/csv";

export const DOCUMENTS_DEFAULT_SORTER: DocumentSearchSort = {
  sortBy: "createdAt",
  sortDirection: "desc",
};

export const INITIAL_SEARCH_REQUEST: DocumentSearchRequest = {
  sort: { ...DOCUMENTS_DEFAULT_SORTER },
  pagination: {
    ...DEFAULT_TABLE_PAGINATION,
  },
  filter: {},
};
