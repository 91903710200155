import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  deleteDocumentsAPI,
  fetchDocumentAPI,
  uploadDocumentsAPI,
  fetchDocumentStatsAPI,
  searchTagsAPI,
  searchDocumentsAPI,
} from "api/documents";
import {
  DocumentStats,
  IDocument,
  SearchDocumentsResponse,
  TagSearchResponse,
  DocumentSearchRequest,
} from "models/documents.model";
import { InterceptedResponse, Status } from "models/shared";

interface DocumentsState {
  isFetchingDocument: Status;
  isUploadingDocuments: Status;
  isDeletingDocument: Status;
  isFetchingDocumentStats: Status;
  isSearchingTags: Status;
  isSearchingDocuments: Status;
  documentsList: SearchDocumentsResponse;
  selectedDocument: IDocument;
  documentStats: DocumentStats;
  tagSearchResults: TagSearchResponse;
}

const initialState = {
  isFetchingDocument: "idle",
  isUploadingDocuments: "idle",
  isDeletingDocument: "idle",
  isFetchingDocumentStats: "idle",
  isSearchingTags: "idle",
  isSearchingDocuments: "idle",
  documentsList: {},
  selectedDocument: {} as IDocument,
  documentStats: {
    queued: 0,
    processing: 0,
    successful: 0,
    failed: 0,
    total: -1,
  },
  tagSearchResults: {
    entities: [],
    totalCount: 0,
  } as TagSearchResponse,
} as DocumentsState;

export const fetchDocument = createAsyncThunk(
  "documents/fetchDocument",
  async (id: string) => {
    const response = await fetchDocumentAPI(id);
    return response;
  }
);

export const uploadDocuments = createAsyncThunk(
  "documents/uploadDocuments",
  async (formData: FormData) => {
    const response = await uploadDocumentsAPI(formData);
    return response;
  }
);

export const deleteDocuments = createAsyncThunk(
  "documents/deleteDocuments",
  async (ids: string[]) => {
    const response = await deleteDocumentsAPI(ids);
    return response;
  }
);

export const fetchDocumentStats = createAsyncThunk(
  "documents/fetchDocumentStats",
  async () => {
    const response = await fetchDocumentStatsAPI();
    return response;
  }
);

export const searchTags = createAsyncThunk(
  "documents/searchTags",
  async (request: { filter: string; limit: number }) => {
    const response = await searchTagsAPI(request);
    return response;
  }
);

export const searchDocuments = createAsyncThunk(
  "documents/searchDocuments",
  async (request: DocumentSearchRequest) => {
    const response = await searchDocumentsAPI(request);
    return response;
  }
);

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDocument.pending, (state: DocumentsState) => {
        state.isFetchingDocument = "loading";
      })
      .addCase(
        fetchDocument.fulfilled,
        (state: DocumentsState, { payload }) => {
          state.isFetchingDocument = "succeeded";
          state.selectedDocument = (payload as InterceptedResponse).data;
        }
      )
      .addCase(fetchDocument.rejected, (state: DocumentsState) => {
        state.isFetchingDocument = "failed";
      })
      .addCase(uploadDocuments.pending, (state: DocumentsState) => {
        state.isUploadingDocuments = "loading";
      })
      .addCase(uploadDocuments.fulfilled, (state: DocumentsState) => {
        state.isUploadingDocuments = "succeeded";
      })
      .addCase(uploadDocuments.rejected, (state: DocumentsState) => {
        state.isUploadingDocuments = "failed";
      })
      .addCase(deleteDocuments.pending, (state: DocumentsState) => {
        state.isDeletingDocument = "loading";
      })
      .addCase(deleteDocuments.fulfilled, (state: DocumentsState) => {
        state.isDeletingDocument = "succeeded";
      })
      .addCase(deleteDocuments.rejected, (state: DocumentsState) => {
        state.isDeletingDocument = "failed";
      })
      .addCase(fetchDocumentStats.pending, (state: DocumentsState) => {
        state.isFetchingDocumentStats = "loading";
      })
      .addCase(
        fetchDocumentStats.fulfilled,
        (state: DocumentsState, { payload }) => {
          state.isFetchingDocumentStats = "succeeded";
          state.documentStats = (payload as InterceptedResponse).data;
        }
      )
      .addCase(fetchDocumentStats.rejected, (state: DocumentsState) => {
        state.isFetchingDocumentStats = "failed";
      })
      .addCase(searchTags.pending, (state: DocumentsState) => {
        state.isSearchingTags = "loading";
      })
      .addCase(searchTags.fulfilled, (state: DocumentsState, { payload }) => {
        state.isSearchingTags = "succeeded";
        state.tagSearchResults = (payload as InterceptedResponse).data;
      })
      .addCase(searchTags.rejected, (state: DocumentsState) => {
        state.isSearchingTags = "failed";
      })
      .addCase(searchDocuments.pending, (state: DocumentsState) => {
        state.isSearchingDocuments = "loading";
      })
      .addCase(
        searchDocuments.fulfilled,
        (state: DocumentsState, { payload }) => {
          state.isSearchingDocuments = "succeeded";
          state.documentsList = (payload as InterceptedResponse).data;
        }
      )
      .addCase(searchDocuments.rejected, (state: DocumentsState) => {
        state.isSearchingDocuments = "failed";
      });
  },
});

export default documentsSlice.reducer;

export const { reset } = documentsSlice.actions;
