import { ThemeConfig } from "antd";

import { lightColorPalette, lightPalette } from "./light-palette";

export const lightTheme: ThemeConfig = {
  token: {
    borderRadius: 0,
    fontFamily: "Oxygen",
    colorText: lightColorPalette.grey[900],
    colorPrimary: lightColorPalette.purple[500],
    colorLink: lightColorPalette.purple[500],
  },
  components: {
    Menu: {
      itemSelectedBg: lightColorPalette.grey[175],
    },
    Button: {
      borderRadius: 57,
      controlHeight: 42,
      fontWeight: 700,
      colorTextDisabled: lightColorPalette.grey[300],
      borderColorDisabled: "transparent",
      colorBgContainerDisabled: lightColorPalette.grey[125],
    },
    Upload: {
      colorFillAlter: lightColorPalette.white[200],
      borderRadiusLG: 9,
    },
    Tree: {
      nodeHoverBg: lightColorPalette.grey[175],
    },
    Tag: {
      borderRadiusSM: 5,
    },
    Descriptions: {
      borderRadiusLG: 5,
    },
    Card: {
      borderRadiusLG: 7,
    },
  },
};

const createCssVar = (items: any, prefix = "-"): string[] =>
  Object.entries(items).flatMap(([key, value]) => {
    const varName = `${prefix}-${key}`;
    if (typeof value === "object") return createCssVar(value, varName);
    return `${varName}:${value}`;
  });

const createCssVars = (themeColors: any) => createCssVar(themeColors).join(";");
const sheet = document.styleSheets[0];
sheet.insertRule(`:root{${createCssVars(lightPalette)}}`, 1);

export {};
