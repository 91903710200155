import "./App.scss";

import { Spin } from "antd";
import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router";

import BaseLayout from "layout/BaseLayout";

const Documents = lazy(() => import("pages/Documents"));
const Taxonomy = lazy(() => import("pages/Taxonomy"));
const DocumentDetails = lazy(() => import("pages/DocumentDetails"));
const BenchmarkingWrapper = lazy(() => import("pages/Benchmarking"));
const Metrics = lazy(() => import("pages/Metrics"));
const Error = lazy(() => import("pages/Error"));

const App = () => (
  <Suspense fallback={<Spin />}>
    <Routes>
      <Route path="/" element={<Navigate to="/documents" />} />
      <Route
        path="/documents"
        element={<BaseLayout component={<Documents />} />}
      />
      <Route
        path="/documents/:id"
        element={<BaseLayout component={<DocumentDetails />} hideSider />}
      />
      <Route
        path="/products"
        element={<BaseLayout component={<Taxonomy />} />}
      />
      <Route
        path="/situations"
        element={<BaseLayout component={<Taxonomy />} />}
      />
      <Route
        path="/benchmarking-list/products"
        element={<BaseLayout component={<BenchmarkingWrapper />} />}
      />
      <Route
        path="/benchmarking-list/situations"
        element={<BaseLayout component={<BenchmarkingWrapper />} />}
      />
      <Route
        path="/benchmarking-metrics"
        element={<BaseLayout component={<Metrics />} />}
      />
      <Route path="/error" element={<BaseLayout component={<Error />} />} />
    </Routes>
  </Suspense>
);

export default App;
