import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchTagsAPI,
  fetchTagsReportAPI,
  uploadTagsAPI,
  deleteTagsAPI,
  downloadTagsTemplateAPI,
} from "api/tags";
import { MentionType } from "models/documents.model";
import { InterceptedResponse, Status } from "models/shared";
import {
  FetchTagsRequest,
  FetchTagsResponse,
  TagsReport,
  UploadTagsAPIRequest,
} from "models/tags.model";

const slice = "tags";

interface TagsState {
  isUploadingTagsFile: Status;
  isFetchingTags: Status;
  isFetchingProductTagsReport: Status;
  isFetchingSituationTagsReport: Status;
  productTagsReport: TagsReport;
  situationTagsReport: TagsReport;
  isDeletingTags: Status;
  isDownloadingTemplate: Status;
}

const initialState = {
  isUploadingTagsFile: "idle",
  isFetchingTags: "idle",
  isFetchingProductTagsReport: "idle",
  isFetchingSituationTagsReport: "idle",
  isDeletingTags: "idle",
  isDownloadingTemplate: "idle",
} as TagsState;

export const uploadTags = createAsyncThunk(
  `${slice}/uploadTags`,
  async (request: UploadTagsAPIRequest) => {
    const response = await uploadTagsAPI(request);
    return response;
  }
);

export const fetchTags = createAsyncThunk(
  `${slice}/fetchTags`,
  async (request: FetchTagsRequest) => {
    const response = await fetchTagsAPI(request);
    return response;
  }
);

export const fetchProductTagsReport = createAsyncThunk(
  `${slice}/fetchProductTagsReport`,
  async () => {
    const response = await fetchTagsReportAPI("Product");
    return response;
  }
);

export const fetchSituationTagsReport = createAsyncThunk(
  `${slice}/fetchSituationTagsReport`,
  async () => {
    const response = await fetchTagsReportAPI("Situation");
    return response;
  }
);

export const deleteTags = createAsyncThunk(
  `${slice}/deleteTags`,
  async (entityType: MentionType) => {
    const response = await deleteTagsAPI(entityType);
    return response;
  }
);

export const downloadTagsTemplate = createAsyncThunk(
  `${slice}/downloadTagsTemplate`,
  async () => {
    const response = await downloadTagsTemplateAPI();
    return response;
  }
);

export const tagsSlice = createSlice({
  name: slice,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(uploadTags.pending, (state: TagsState) => {
        state.isUploadingTagsFile = "loading";
      })
      .addCase(uploadTags.fulfilled, (state: TagsState) => {
        state.isUploadingTagsFile = "succeeded";
      })
      .addCase(uploadTags.rejected, (state: TagsState) => {
        state.isUploadingTagsFile = "failed";
      })
      .addCase(fetchTags.pending, (state: TagsState) => {
        state.isFetchingTags = "loading";
      })
      .addCase(fetchTags.fulfilled, (state: TagsState) => {
        state.isFetchingTags = "succeeded";
      })
      .addCase(fetchTags.rejected, (state: TagsState) => {
        state.isFetchingTags = "failed";
      })
      .addCase(fetchProductTagsReport.pending, (state: TagsState) => {
        state.isFetchingProductTagsReport = "loading";
      })
      .addCase(
        fetchProductTagsReport.fulfilled,
        (state: TagsState, { payload }) => {
          state.isFetchingProductTagsReport = "succeeded";
          state.productTagsReport = (payload as InterceptedResponse).data;
        }
      )
      .addCase(fetchProductTagsReport.rejected, (state: TagsState) => {
        state.isFetchingProductTagsReport = "failed";
      })
      .addCase(fetchSituationTagsReport.pending, (state: TagsState) => {
        state.isFetchingSituationTagsReport = "loading";
      })
      .addCase(
        fetchSituationTagsReport.fulfilled,
        (state: TagsState, { payload }) => {
          state.isFetchingSituationTagsReport = "succeeded";
          state.situationTagsReport = (payload as InterceptedResponse).data;
        }
      )
      .addCase(fetchSituationTagsReport.rejected, (state: TagsState) => {
        state.isFetchingSituationTagsReport = "failed";
      })
      .addCase(deleteTags.pending, (state: TagsState) => {
        state.isDeletingTags = "loading";
      })
      .addCase(deleteTags.fulfilled, (state: TagsState) => {
        state.isDeletingTags = "succeeded";
      })
      .addCase(deleteTags.rejected, (state: TagsState) => {
        state.isDeletingTags = "failed";
      })
      .addCase(downloadTagsTemplate.pending, (state: TagsState) => {
        state.isDownloadingTemplate = "loading";
      })
      .addCase(downloadTagsTemplate.fulfilled, (state: TagsState) => {
        state.isDownloadingTemplate = "succeeded";
      })
      .addCase(downloadTagsTemplate.rejected, (state: TagsState) => {
        state.isDownloadingTemplate = "failed";
      });
  },
});

export default tagsSlice.reducer;
