export const lightColorPalette = {
  white: {
    100: "#FBFBFD", // Lightest
    200: "#fff", // Default White
    300: "#f9f9f9", // Off White
  },
  grey: {
    50: "#F5F7FA", // Lightest Grey
    100: "#EBECFC", // Lighter Grey
    125: "#F1F2F7", // Light Grey
    150: "#DDE8F2", // Light Grey
    175: "#DADAED4D", // Semi-transparent Light Grey
    200: "#f5f5f5", // Subtle Grey
    250: "#e5e5e5", // Muted Grey
    300: "#A0A0A0", // Medium Grey
    350: "#828282", // Dark Grey
    400: "#d0d0d0", // Border Grey
    500: "#e0e0e0", // Inactive Grey
    900: "#393956", // Darkest Grey
  },
  purple: {
    100: "#6a6a9f33", // Transparent Light Purple (20% opacity of 300)
    300: "#6A6A9F", // Primary Purple
    350: "#6D76DC", // Accent Purple
    400: "#8E8EBC", // Muted Purple
    500: "#4251ED", // Default Purple
    700: "#2D369F", // Hover Purple
  },
  red: {
    400: "#FF4F4F", // Error Red
  },
  green: {
    500: "#0EBC15", // Success Green
  },
  blue: {
    500: "#007bff", // Primary Blue
  },
  yellow: {
    300: "#FDFF92", // Highlight Yellow
  },
};

export const lightPalette = {
  layout: {
    headerBg: lightColorPalette.white[200],
    contentBg: lightColorPalette.white[100],
  },
  icons: {
    primary: lightColorPalette.purple[300],
  },
  text: {
    secondary: lightColorPalette.purple[300],
  },
};
