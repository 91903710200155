import { DocumentSearchRequest } from "models/documents.model";

import { axiosConfig } from "./axios";

const baseUrl = "documents";

export const fetchDocumentAPI = async (id: string) => {
  const url = `${baseUrl}/${id}`;
  const response = await axiosConfig("GET", url);
  return response;
};

export const fetchDocumentStatsAPI = async () => {
  const url = `${baseUrl}/stats`;
  const response = await axiosConfig("GET", url);
  return response;
};

export const uploadDocumentsAPI = async (formData: FormData) => {
  const url = `${baseUrl}`;
  const response = await axiosConfig("POST", url, formData, true);
  return response;
};

export const deleteDocumentsAPI = async (ids: string[]) => {
  const url = `${baseUrl}`;
  const response = await axiosConfig("DELETE", url, ids);
  return response;
};

export const searchTagsAPI = async (request: {
  filter: string;
  limit: number;
}) => {
  const { filter, limit } = request;
  const url = `tags?filter=${filter}&limit=${limit}`;
  const response = await axiosConfig("GET", url);
  return response;
};

export const searchDocumentsAPI = async (request: DocumentSearchRequest) => {
  const url = `${baseUrl}/search`;
  const response = await axiosConfig("POST", url, request);
  return response;
};
